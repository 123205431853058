import isRequestInProgress from 'redux/utils/isRequestInProgress';
import { shouldShowPayment } from 'redux/modules/Energy/ProductSelectionForm/helpers';

import {
  STORE_NAME,
  PREPAID_GAS,
  PREPAID_ELECTRICITY,
  PREPAID_ELECTRICITY_AND_GAS,
  PRODUCT_GAS,
  PRODUCT_ELECTRICITY,
  PRODUCT_ELECTRICITY_AND_GAS,
  METER_ECONOMY7,
  PAYMENT_TYPE_PREPAID,
  PAYMENT_PLAN_BUDGET,
} from './constants';

export class EnergyProductSelectionApi {
  static getPaymentPlan(state) {
    const energy = state.getIn([STORE_NAME, 'fields', 'energy']);
    const paymentType = state.getIn([STORE_NAME, 'fields', 'paymentType']);
    const prepaid = state.getIn([STORE_NAME, 'fields', 'prepaid']);
    const paymentPlan = state.getIn([STORE_NAME, 'fields', 'paymentPlan']);

    // budget is a default option if we don't show the payment selector
    if (!shouldShowPayment(energy, paymentType, prepaid))
      return PAYMENT_PLAN_BUDGET;
    else return paymentPlan;
  }

  static isOnlyPrePayment(state) {
    const energy = state.getIn([STORE_NAME, 'fields', 'energy']);
    const paymentType = state.getIn([STORE_NAME, 'fields', 'paymentType']);
    const prepaid = state.getIn([STORE_NAME, 'fields', 'prepaid']);

    if (paymentType !== PAYMENT_TYPE_PREPAID) return false;
    switch (energy) {
      case PRODUCT_ELECTRICITY_AND_GAS:
        return prepaid === PREPAID_ELECTRICITY_AND_GAS;
      case PRODUCT_ELECTRICITY:
        return prepaid === PREPAID_ELECTRICITY;
      case PRODUCT_GAS:
        return prepaid === PREPAID_GAS;
      default:
        return false;
    }
  }

  static isElectricityPrepaid(state) {
    const paymentType = state.getIn([STORE_NAME, 'fields', 'paymentType']);
    if (paymentType !== PAYMENT_TYPE_PREPAID) return false;

    const prepaid = state.getIn([STORE_NAME, 'fields', 'prepaid']);
    return (
      prepaid === PREPAID_ELECTRICITY || prepaid === PREPAID_ELECTRICITY_AND_GAS
    );
  }

  static getProvidedElectricityDayConsumption(state) {
    return state.getIn([STORE_NAME, 'fields', 'electricityDayConsumption']);
  }

  static getProvidedElectricityNightConsumption(state) {
    return state.getIn([STORE_NAME, 'fields', 'electricityNightConsumption']);
  }

  static isEconomy(state) {
    return state.getIn([STORE_NAME, 'fields', 'meter']) === METER_ECONOMY7;
  }

  static isRequestInProgress(state) {
    return isRequestInProgress(state, STORE_NAME);
  }

  static getEnergySelection(state) {
    return state.getIn([STORE_NAME, 'fields', 'energy']);
  }

  static getFormFields(state) {
    return state.getIn([STORE_NAME, 'fields']).toJS();
  }
}
