import { authenticatedPost } from 'redux/utils/request';
import { get } from 'lodash';

export const postCreateLead = (params) =>
  authenticatedPost('leads/create', {
    cmp: {
      id: get(params, 'campaign_id'),
      utmCampaign: get(params, 'utm_campaign'),
      utmMedium: get(params, 'utm_medium'),
      utmSource: get(params, 'utm_source'),
      utmContent: get(params, 'utm_content'),
      utmTerm: get(params, 'utm_term'),
      clickId: get(params, 'click_id'),
      network: get(params, 'network'),
    },
  });
